import { Component, Input, OnInit } from '@angular/core';
import { AktivitetMTid } from '../../plan/AktivitetMTid';

@Component({
  selector: 'app-sjoforrunde',
  templateUrl: './sjoforrunde.component.html',
  styleUrls: ['./sjoforrunde.component.css']
})
export class SjoforrundeComponent implements OnInit {

  constructor() { }

 
  @Input() tittel: string;
  @Input() liste: AktivitetMTid[];
  @Input() runde;
  displayElement = false;
  color = "000"



  ngOnInit(): void {
    if(this.liste.length === 0){
      this.displayElement = true;
    };

  
  }

}
