import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './ompro.component.html',
  styleUrls: ['./ompro.component.css']
})
export class OmProsjektetComponent{
  constructor(public dialogRef: MatDialogRef<OmProsjektetComponent>) { }

  public confirmMessage: string;
  public rodTekst: string;
  public hovedtekst: string;

  ngOnInit() {
  }
}
