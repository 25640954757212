import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EksporterService } from 'src/environments/envireksporter/eksporter.service';
import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PubliserValgComponent } from '../publiser-valg/publiser-valg.component';
import { PlanService } from '../../environments/plan.service';
import { Leir } from '../plan/master-plan.component';


@Component({
  selector: 'app-eksporter',
  templateUrl: './eksporter.component.html',
  styleUrls: ['./eksporter.component.css']
})
export class EksporterComponent implements OnInit {

  constructor(private http: HttpClient, private hentData: EksporterService, private dialog: MatDialog, private service: PlanService) {
  }

  datoDag1: string = "Laster..";
  datoDag2: string = "Laster..";
  datoDag3: string = "Laster..";

  prosjektnummer = "";
  profundoLagretOk = "";

  eposter: string = "Laster..";
  mailstring: string = "";
  synlighetLeirbobla = "?";
  leirId: number = -1;
  //myHero = String(eposter[1]);

  //myHero = this.eposter[1];
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  async ngOnInit() {
    this.hentDatoForLeir()

    this.hentData.getEposter().subscribe(
      (res: any) => {
        this.eposter = res.verdi
        this.hentMailstring();
      }
    );

    timer(300).subscribe(x => {
    //console.log(this.eposter[1])
      
    })
    //this.hei = this.eposter[1];
  }

  lagreProsjektnummer(){
    console.log("...")
    this.hentData.endreProsjektnummer(this.prosjektnummer).subscribe((res : any) => {
        if(res.result.toString() === this.prosjektnummer.toString()){
          this.profundoLagretOk = "Prosjektnummer er oppdatert!"
        }
      })
  }

  opnePlanDialog() {
    this.dialog.open(PubliserValgComponent, {
      disableClose: false,
      autoFocus: false,
    });
  }

  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.eposter;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  okEndreLeirboblaMld: string;

  endreSynlighetLeirbobla(val : any){
    this.synlighetLeirbobla = val.value;
    this.okEndreLeirboblaMld = "";
    this.hentData.endreLeiboblaAktiv(val.value).subscribe((res : any) => {
      if(res.result.toString() === val.value.toString()){
        this.okEndreLeirboblaMld = "Lagret!"
      }
      else{
        this.okEndreLeirboblaMld = "Feil!"
      }
    })
  }

  hentDatoForLeir() {
    this.service.getAktivLeir().subscribe((leir: Leir) => {

      this.synlighetLeirbobla = leir.erTransportplanAktiv.toString();
      this.leirId = leir.id;
      this.prosjektnummer = leir.profundoProsjektnummer;
      //this.datoDag1 = leir.datoFra.toLocaleTimeString();
      var date = new Date();
      var date2 = new Date(leir.datoFra);
      const dato_fra = new Date(date2.getTime() - (date.getTimezoneOffset() * 60000));

      var day = (60 * 60 * 24 * 1000)

      let datoDag1: Date = new Date(dato_fra.getTime() + day);
      let datoDag2: Date = new Date(dato_fra.getTime() + (day * 2));
      const datoDag3: Date = new Date(dato_fra.getTime() + (day * 3));
     
      this.datoDag1 = datoDag1.toJSON().slice(8, 10) + "." + datoDag1.toJSON().slice(5, 7);
      this.datoDag2 = datoDag2.toJSON().slice(8, 10) + "." + datoDag2.toJSON().slice(5, 7);
      this.datoDag3 = datoDag3.toJSON().slice(8, 10) + "." + datoDag3.toJSON().slice(5, 7);

      
      

    })
  }


  hentMailstring() {
    var today = new Date();
    today.setDate(today.getDate() + 1);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    let mailto = "mailto:";
    let adresser = this.eposter;
    let subject = "TRANSPORTPLAN - FOR: " + dd + '/' + mm + '/' + yyyy;
    this.mailstring = mailto + adresser + "?subject=" + subject;

  }





}
