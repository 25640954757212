import { Component, Input, OnInit } from '@angular/core';
import { AktivitetMTid } from 'src/app/plan/AktivitetMTid';

@Component({
  selector: 'app-utskrift-enhet',
  templateUrl: './utskrift-enhet.component.html',
  styleUrls: ['./utskrift-enhet.component.css']
})
export class UtskriftEnhetComponent implements OnInit {

  
    @Input() tittel: string;
    @Input() liste: AktivitetMTid[];
    @Input() runde;
    @Input() lengdeRunde1;
    displayElement = false;
    color = "000"
  
  
  
    ngOnInit(): void {
      if(this.liste.length === 0){
        this.displayElement = true;
      };
  
    
    }
  

}
