import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class BrukerService {

  constructor(private http: HttpClient) { }




  post(Body) {
    return this.http.post(environment.apiBaseURI + '/Bruker/Registrer', Body)
  }

  loggInn(Body) {
    return this.http.post(environment.apiBaseURI + '/Bruker/LoggInn', Body);
  }

  getBrukerprofil() {
    return this.http.get(environment.apiBaseURI + '/Brukerprofil');
  }

  slettBruker() {
    return this.http.get(environment.apiBaseURI + '/Bruker/Slett');
  }

  endrePassord(body) {
    return this.http.post(environment.apiBaseURI + '/Bruker/NyttPassord', body, { responseType: 'text' })
  }

  endreBrukerprofil(body) {
    return this.http.put(environment.apiBaseURI + '/Bruker/endreProfil', body, { responseType: 'text' });
  }

  getAlleLeirer() {
    return this.http.get(environment.apiBaseURI + '/Leir/GetAlleLeirer');
  }

  endreLeir(nyLeirId : number) {
    return this.http.get(environment.apiBaseURI + '/Bruker/EndreLeir?id=' + nyLeirId);
  }

}

