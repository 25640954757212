import { LokaleService } from 'src/environments/envirlokale/lokale.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, FormArray, Validators, FormGroup, NgForm, AbstractControl, ValidatorFn } from '@angular/forms';
import { AktivitetService } from 'src/environments/enviraktivitet/aktivitet.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable, BehaviorSubject, combineLatest, timer } from 'rxjs';
import { parse } from 'querystring';
import { error } from 'protractor';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CsvComponent } from '../csv/csv.component';
import { Route, Router } from '@angular/router';
import { AktivitetMTid } from '../plan/AktivitetMTid';
import { SplittComponent } from '../splitt/splitt.component';
import { IfStmt } from '@angular/compiler';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'aktivitet',
  templateUrl: './aktivitet.component.html',
  styleUrls: ['./aktivitet.component.css']
})




export class AktivitetComponent implements OnInit, AfterViewInit {


    matSelectLokaleWidth: number = 40;
    matSelectLokaleWidthOffset: number = 0;

  totaltDeltakere = 0;
  totaltLedere = 0;
  antallMTransportEllerDelvisTransport = 0;
  antallMDelvisTransport = 0;

  showSpinner = true;
  ingenAktiviteter = false;

  ValidateUrl(control: AbstractControl) {
  if (!control.value.startsWith('https') || !control.value.includes('.io')) {
    return { validUrl: true };
  }
  return null;
}

  openLasterSnackBar(){
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white'];
    config.duration = 20000;
    this.snackBar.open("Laster..", undefined, config);
  }

  openSnackBar(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white'];
    config.duration = 2000;
    this.snackBar.open(message, undefined, config);
  }

  openSnackBarObs(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-red'];
    config.duration = 3000;
    this.snackBar.open(message, undefined, config);
  }

  @ViewChild('form')
  form: NgForm;


  aktiviteter: FormArray = this.fb.array([]);
  lokaler = [];
  notification = null;
  minFG: FormGroup


  constructor(private fb: FormBuilder, private dialog: MatDialog, private router: Router, private dialogRef: MatDialogRef<SplittComponent>,
    private bankService: LokaleService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private service: AktivitetService, private snackBar: MatSnackBar, private lasterSnackBar: MatSnackBar, private formBuilder: FormBuilder) {

        this.matIconRegistry.addSvgIcon(
            "different-lokale", this.domSanitizer.bypassSecurityTrustResourceUrl("different-lokale.svg")
          );

        this.matIconRegistry.addSvgIcon(
            "same-lokale", this.domSanitizer.bypassSecurityTrustResourceUrl("same-lokale.svg")
          );
  }

  /*oppdaterTall() {


    var array = this.aktiviteter.value




    /*totaltDeltakere = 0;
    totaltAktiviteter = 0;
    totaltLedere = 0;
  }*/

  lastInn() {
    //Alle tilgjengelige lokaler
    this.bankService.getLokale()
      .subscribe(res => {
        (res as []).forEach((lokale: any) => {

            if(lokale.lokaleTilIngenTransport == true){
              lokale.navn = "Trenger ikke transport";
            }
            lokale.fontWeight = 500;
          this.lokaler.push(lokale);
        })
        this.lokaler[0].fontWeight = 600;
      })

    //Aktiviteter
    this.service.getAktivitet().subscribe(
      res => {
        this.showSpinner = false;

        if ((res as []).length == 0)
          this.ingenAktiviteter = true;
        else {

          //Oppdaterer tall
          (res as []).forEach((akt: any) => {

            //Tall
            /*this.totaltDeltakere = this.totaltDeltakere + akt.antallDeltakere;
            this.totaltLedere = this.totaltLedere + akt.antallLedere;*/


            //kommentert ut da det er usikkert hva dette faktisk gjør?
           /* var tekstFarge = "#000000"
            if (akt.lokaleD1.lokaleTilIngenTransport && akt.lokaleD2.lokaleTilIngenTransport && akt.lokaleD3.lokaleTilIngenTransport) {
                tekstFarge = "#616161";
            }
            if (akt.lokaleD1.lokaleTilIngenTransport || akt.lokaleD2.lokaleTilIngenTransport || akt.lokaleD3.lokaleTilIngenTransport) {
                tekstFarge = "#ba4343";

            }*/


            this.aktiviteter.push(
              this.fb.group({
                tekstFarge: "#000000",
                lokaleD1: this.fb.group({
                    id: [akt.lokaleD1.id, Validators.min(1)],
                    //True eller false variabel
                    lokaleTilIngenTransport: [akt.lokaleD1.lokaleTilIngenTransport]
                }),
                lokaleD2: this.fb.group({
                    id: [akt.lokaleD2.id, Validators.min(1)],
                    //True eller false variabel
                    lokaleTilIngenTransport: [akt.lokaleD2.lokaleTilIngenTransport]
                  }),
                lokaleD3: this.fb.group({
                    id: [akt.lokaleD3.id, Validators.min(1)],
                    //True eller false variabel
                    lokaleTilIngenTransport: [akt.lokaleD3.lokaleTilIngenTransport]
                }),
                iForskjelligLokaleModus : false,
                id: [akt.id],
                CSV: [akt.csv],
                navn: [akt.navn, Validators.required],
                antallDeltakere: [akt.antallDeltakere, Validators.required],
                antallLedere: [akt.antallLedere, Validators.required],
                kapasitet: [akt.kapasitet],
                kommentar: [akt.kommentar],
                //Hi
                fornavn: [akt.fornavn],
                etternavn: [akt.etternavn],
                epost: [akt.epost],
                telefonnr: [akt.telefonnr],
                leirId : [akt.leirId]
              }
              ));
          });

          //Oppdater tall
          this.oppdaterTall();
          //Sorter listen
          this.sorter();
        }
      });

  }


  @Output() messageEvent = new EventEmitter<string>();

  splittAktivitet(akt: any) {

    let aktMTid : any = {};
    aktMTid.aktivitet = akt;

    this.dialogRef = this.dialog.open(SplittComponent, {
      disableClose: false,
      autoFocus: true,
      data: aktMTid,
      maxWidth : "80vw",
      maxHeight : "90vh",
      minWidth : "80vw",
      minHeight : "90vh"
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
      //Må fikse å kunne oppdatere, se https://stackoverflow.com/questions/37587732/how-to-call-another-components-function-in-angular2
     // this.messageEvent.emit("ok");

      this.dialogRef = null;
    });
  }

  getSortingValue(a : any){

    if(a.lokaleD1.lokaleTilIngenTransport && a.lokaleD2.lokaleTilIngenTransport && a.lokaleD3.lokaleTilIngenTransport){
        return 3;
    }
    if(a.lokaleD1.lokaleTilIngenTransport || a.lokaleD2.lokaleTilIngenTransport || a.lokaleD3.lokaleTilIngenTransport){
        return 2;
    }
    return 1;
  }

  sorter() {

    this.antallMTransportEllerDelvisTransport = this.aktiviteter.length;
    this.antallMDelvisTransport = this.aktiviteter.length;

    //oppdatere antall med delvis/ikke/og hel transport
    this.aktiviteter.value.forEach((akt: any) => {
        akt.iForskjelligLokaleModus = false;
        if (akt.lokaleD1.lokaleTilIngenTransport && akt.lokaleD2.lokaleTilIngenTransport && akt.lokaleD3.lokaleTilIngenTransport) {
            this.antallMTransportEllerDelvisTransport--;
        }
        if (akt.lokaleD1.lokaleTilIngenTransport || akt.lokaleD2.lokaleTilIngenTransport || akt.lokaleD3.lokaleTilIngenTransport) {
            this.antallMDelvisTransport--;
        }
    })

    var array = this.aktiviteter;
        array.value.sort((a, b) => (+this.getSortingValue(a)) - (+this.getSortingValue(b)) || a.navn.localeCompare(b.navn)
        );

    this.aktiviteter.patchValue(array.value)
  }


  public ngAfterViewInit(): void {
    /* need _canScrollDown because it triggers even if you enter text in the textarea */
    if(document.getElementById('entire-table') !== null){
        const width = (((document.getElementById('entire-table').offsetWidth - this.matSelectLokaleWidthOffset) / 100) * 8);
        this.matSelectLokaleWidth = (width > 80) ? width : 80;
    }


}

  ngOnInit() {
    this.lastInn();

  }

  endreForskjelligLokaleModus(fg : FormGroup){
    fg.patchValue({
        iForskjelligLokaleModus: !fg.get("iForskjelligLokaleModus").value
    })
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  oppdaterTall() {
    var myArray = this.aktiviteter.value;
    let totaltDeltakere = 0;
    let totaltLedere = 0;

    var i;
    for (i = 0; i < myArray.length; i++) {
      totaltDeltakere = totaltDeltakere + myArray[i].antallDeltakere;
      totaltLedere = + totaltLedere + myArray[i].antallLedere;
    }

    this.totaltDeltakere = totaltDeltakere;
    this.totaltLedere = totaltLedere;

  }

  recordSubmit(fg: FormGroup, alleLokalerLike : string) {

    this.showNotification('laster', fg.value.navn);

    //Endrer akt sitt lokale, i tilfellet det er flyttet til Ingen transport
    const endretTilIngenTpmD1: boolean = this.lokaler.find(x => x.id === fg.value.lokaleD1.id).lokaleTilIngenTransport;
    const endretTilIngenTpmD2: boolean = this.lokaler.find(x => x.id === fg.value.lokaleD2.id).lokaleTilIngenTransport;
    const endretTilIngenTpmD3: boolean = this.lokaler.find(x => x.id === fg.value.lokaleD3.id).lokaleTilIngenTransport;

    /*const fraIngenTpmD1: boolean = fg.value.lokaleD1.lokaleTilIngenTransport;
    const fraIngeTpmD2: boolean = fg.value.lokaleD2.lokaleTilIngenTransport;
    const fraIngeTpmD3: boolean = fg.value.lokaleD3.lokaleTilIngenTransport;*/

    fg.value.lokaleD1.lokaleTilIngenTransport = endretTilIngenTpmD1;
    fg.value.lokaleD2.lokaleTilIngenTransport = endretTilIngenTpmD2;
    fg.value.lokaleD3.lokaleTilIngenTransport = endretTilIngenTpmD3;



    /*if(fg.value.lokaleD1.id === fg.value.lokaleD2.id && fg.value.lokaleD1.id === fg.value.lokaleD3.id){
        fg.patchValue({
            iForskjelligLokaleModus: false
        })
    }*/


   // this.antallMDelvisTransport = this.aktiviteter.length;
    //this.antallMTransportEllerDelvisTransport = this.aktiviteter.length;

    //hvis i står i alle like, og alle
    if(alleLokalerLike === "true"){
        fg.patchValue({
            lokaleD2: {
                id: fg.value.lokaleD1.id,
                lokaleTilIngenTransport: fg.value.lokaleD1.lokaleTilIngenTransport
            },
            lokaleD3: {
                id: fg.value.lokaleD1.id,
                lokaleTilIngenTransport : fg.value.lokaleD1.lokaleTilIngenTransport
            }
        });
    }

    fg.patchValue({
        iForskjelligLokaleModus: false
    })



   /*setTimeout(() => {
        //Oppdater tall
        this.oppdaterTall();
        //Sorter listen
        this.sorter();
    }, 200)*/


      this.service.putLokale(fg.value).subscribe(
        response => {

          if ((fg.value.antallDeltakere + fg.value.antallLedere) > fg.value.kapasitet) {
            this.showNotification('obs', fg.value.navn);
          }
          else {
            this.showNotification('update', fg.value.navn);
          }

          this.sorter();
          this.oppdaterTall();






        }, error => {
          if (error.error.message === "Fant ingen transportmiddler. Opprett et transpormiddel.") {
            this.showNotification('ingenTpm', fg.value.navn);
            this.antallMTransportEllerDelvisTransport--;

            fg.patchValue({
              lokaleD1: {
                id: this.lokaler[0].id,
              },
              lokaleD2: {
                id: this.lokaler[0].id
              },
              lokaleD3: {
                id: this.lokaler[0].id
              }
            });
          }
        else {
            this.showNotification('feil', fg.value.navn);
        }

      });


  }

  lastOpp() {
    const dialogConfig = new MatDialogConfig();

    //Settings
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "90vw";
    dialogConfig.maxHeight = '90vh';
    dialogConfig.autoFocus = false;

    let dialogRef = this.dialog.open(CsvComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigateByUrl('/lokaler', { skipLocationChange: true }).then(() => {
        this.router.navigate(['aktivitet']);
      });
    });
  }

  onDelete(id, i, aktivitet) {



    if (id === 0){
        this.aktiviteter.removeAt(i);
    }


    else if (confirm('Er du sikker på at du vil slette denne aktiviteten?'))
      this.showNotification('laster', "-");
      this.service.slettLokale(id).subscribe(
        (res : any) => {
          if(res.status === 200){


            this.showNotification('delete', aktivitet.navn);
            if (aktivitet.lokaleD1.lokaleTilIngenTransport && aktivitet.lokaleD2.lokaleTilIngenTransport && aktivitet.lokaleD3.lokaleTilIngenTransport) {
              this.antallMTransportEllerDelvisTransport--;
            }
            if (aktivitet.lokaleD1.lokaleTilIngenTransport || aktivitet.lokaleD2.lokaleTilIngenTransport || aktivitet.lokaleD3.lokaleTilIngenTransport) {
              this.antallMDelvisTransport--;
            }

            console.log(this.aktiviteter.removeAt(i));

            this.sorter();
            this.oppdaterTall();
          }
          else{
            this.showNotification('Noe gikk galt', "-");
          }

        });
    }


  showNotification(category, aktivitet) {
    switch (category) {
      case 'laster':
        this.notification = { class: 'text-primary', message: this.openLasterSnackBar() };
        break;
      case 'insert':
        this.notification = { class: 'text-success', message: this.openSnackBar('Lokalet er lagt til') };
        break;
      case 'update':
        this.notification = { class: 'text-primary', message: this.openSnackBar(aktivitet +" er oppdatert.") };
        break;
      case 'delete':
        this.notification = { class: 'text-danger', message: this.openSnackBar(aktivitet + " er slettet.") };
        break;
      case 'obs':
        this.notification = { class: 'bold-font-red', message: this.openSnackBarObs(aktivitet + " er oppdatert. Aktiviteten ble lagret med negativ ledighet") };
        break;
      case 'feil':
        this.notification = { class: 'bold-font-red', message: this.openSnackBarObs("Ugyldig verdi, endring ikke lagret") };
        break;
      case 'fjerner':
        this.notification = { class: 'text-primary', message: this.openSnackBar("Fjerner aktiviteter fra planen..") };
        break;
      case 'leggertil':
        this.notification = { class: 'text-primary', message: this.openSnackBar("Legger til aktiviteter til planen..") };
        break;
      case 'ingenTpm':
        this.notification = { class: 'bold-font-red', message: this.openSnackBarObs("Fant ingen transportmiddler. Opprett et transpormiddel.") };
        break;



      default:
        break;
    }
    setTimeout(() => {
      this.notification = null;
    }, 3000);
  }


}


