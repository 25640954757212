import { Avgang } from "./dag-plan.component";

export class AktivitetMTid{
  turRunde: number;
  public id: number;
  navn: string;
  antall: number;
  avreise: string;
  farge: string;
  info: string;
  aktivitet: any;
  reisebolkid: number;
  rundeTall: number;
  index: number;
  kortNavn: string;
  lat: string;
  lng: string;
  endePaaRute?: boolean;
  auto: boolean;
  avgang?: Avgang;
}
