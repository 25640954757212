import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LokaleService } from 'src/environments/envirlokale/lokale.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { ViewEncapsulation } from '@angular/core';
import { post } from 'jquery';

//declare const google: any;


//declare var google: any;






@Component({
  selector: 'app-fetch-data',
  templateUrl: './nyttLokale.component.html',
  styleUrls: ['./lokaler.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NyttLokaleComponent {

  constructor(private fb: FormBuilder, private service: LokaleService, private router: Router) {
    this.nyttLokale = this.fb.group({
      published: true,
      credentials: this.fb.array([]),
    });
  }


  listeType = [];
  nyttLokale: FormGroup;

  //selectedColor = 'black';

  colors = [
    { name: 'Svart', value: 'black' },
    { name: 'Grå', value: 'gray' },
    { name: 'Mørkeblå', value: 'darkblue' },
    { name: 'Rosa', value: 'deeppink' },
    { name: 'Vinrød', value: 'darkred' },
    { name: 'Grønn', value: 'green' },
    { name: 'Oransje', value: 'orange' },
  ];



  id: FormControl;
  navn: FormControl;
  farge: FormControl;
  omraade: FormControl;
  kommentar: FormControl;
  //Adressse
  gateadresse: FormControl;
  poststed: FormControl;
  postnr: FormControl;
  lat: FormControl;
  lng: FormControl;



  ngOnInit() {
    this.createFormControls();
    this.createForm();

  }

  /*onChange(value){
    this.selectedColor = value;
  }*/




  createFormControls() {
    this.id = new FormControl(0);
    this.navn = new FormControl('', Validators.required);
    this.kommentar = new FormControl('');
    this.omraade = new FormControl('', Validators.required);
    this.farge = new FormControl('black');
    //Adresse
    this.gateadresse = new FormControl({ value: '', disabled: false });
    this.postnr = new FormControl({ value: '', disabled: false });
    this.poststed = new FormControl({ value: '', disabled: false });
    this.lat = new FormControl(undefined, Validators.required);
    this.lng = new FormControl(undefined, Validators.required);
  }

  createForm() {
    this.nyttLokale = new FormGroup({
      id: this.id,
      navn: this.navn,
      kommentar: this.kommentar,
      omraade: this.omraade,
      farge: this.farge,
      //Adresse
      gateadresse: this.gateadresse,
      poststed: this.poststed,
      postnr: this.postnr,
      lat: this.lat,
      lng: this.lng,
    });
  }


  sendingData = false;

  avbryt() {
    //if (!this.nyttLokale.valid) {
      this.router.navigate(['/lokaler']);
    //}
  }

  sendForm() {
    this.sendingData = true;



    this.service.postLokale(this.nyttLokale.value).subscribe(
      data => {
        this.router.navigate(['/lokaler']);
      },
      error => {
        console.error("couldn't post because", error)
        this.sendingData = false;
      }
      //this._router.navigate(['/users', { id: userId }]);
    );
    //timer(600).subscribe(x => { this.router.navigate(['/lokaler']); })
  }



  //KART
  //KART
  //KART




    /*map.addListener("click", (event: any) => {
      marker.setPosition(event.latLng)
    });*/



  FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.minLength(4),

  ]);

}


