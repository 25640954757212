import { Component, Input, OnInit } from '@angular/core';
import { PlanService } from '../../environments/plan.service';

@Component({
  selector: 'app-avreisetid',
  templateUrl: './avreisetid.component.html',
  styleUrls: ['./avreisetid.component.css']
})
export class AvreisetidComponent implements OnInit {

  constructor(private service : PlanService) { }

  public KlAvreiseR1: string = ""
  public KlAvreiseR2 : string = ""
  public okMld : string = ""


  hentAvreisetid(){
    this.service.hentAvreisetid()
      .subscribe((res : any) => {
        console.log(res.klAvreiseR1)
        this.KlAvreiseR1 = res.klAvreiseR1;
        this.KlAvreiseR2 = res.klAvreiseR2
      })
  }

  changeR1(value : string){
    this.KlAvreiseR1 = value
  }

  changeR2(value : string){
    this.KlAvreiseR2 = value
  }

  lagreAvreisted(){
    this.okMld = "Lagrer.."
    if(this.KlAvreiseR1.length < 4 || this.KlAvreiseR2.length < 4){
      this.okMld = "Ugydlig tider";
      return;
    }
    this.service.endreAvreisetidForAlle(this.KlAvreiseR1, this.KlAvreiseR2)
      .subscribe((res : any) => {
        this.okMld = res.msg
       
        setTimeout(() => { window.location.reload()}, 700);
      })
  }





  ngOnInit(): void {
    this.hentAvreisetid()
  }

}
