import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NyttLokaleComponent } from '../nyttLokale.component';

@Component({
  selector: 'app-adresse',
  templateUrl: './adresse.component.html',
  styleUrls: ['./adresse.component.css']
})
export class AdresseComponent implements OnInit {

  edit = false;
  minWidth = "0";

  constructor(@Inject(MAT_DIALOG_DATA) fg: FormGroup, private dialogRef: MatDialogRef<AdresseComponent>) {
    this.nyttLokale = fg
    if (Object.keys(fg).length !== 0) {
      //kommer fra aktiivter
      this.edit = true;

    }
  }


  ngOnInit(): void {
    this.lastKart();
  }

  lagre() {
    this.dialogRef.close(this.nyttLokale)
  }

  avbryt() {
    this.dialogRef.close("avbryt")
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  @Input() nyttLokale: FormGroup;

  private getPlaceAutocomplete() {

    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {

        componentRestrictions: { country: ["NO", "DK", "SE"] },
        // types: ["address"]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.gjortSok = true;
      this.setAdress(place);
    });
  }

  advarselString = "";
  okGateString = "";

  @ViewChild('gateAd') gateAd: ElementRef;

  async setAdress(place: any) {
    this.advarselString = "";
    this.okGateString = "";


   // console.log(place)

    this.nyttLokale.controls['lat'].setValue(place.geometry.location.lat());
    this.nyttLokale.controls['lng'].setValue(place.geometry.location.lng());

   // console.log()
    var poststed = await this.extractFromAdress(place.address_components, "postal_town");
    var postnr = await this.extractFromAdress(place.address_components, "postal_code");
    var gate = await this.extractFromAdress(place.address_components, "route");
    var gateNr = await this.extractFromAdress(place.address_components, "street_number");


   // console.log(gate + " " + gateNr + " " + postnr + " " + poststed);
    
    if (poststed === -1 || postnr === -1 || gate === -1 || gateNr === -1)
      this.advarselString = "<b>Kunne ikke finne en hel adresse, mangler følgende:</b>";
    else
      this.okGateString = "<span>Følgende adresse ble funnet:<br/><b>" + gate + " " + gateNr + ", " + postnr + " " + poststed + "</b><br/>Gjør eventuelle endringer under.</span>";

    (gate === -1) ? (this.advarselString = this.advarselString + "<li>Gate</li>", this.nyttLokale.controls['gateadresse'].setValue("")) : this.nyttLokale.controls['gateadresse'].setValue(gate);
    (gateNr === -1) ? (this.advarselString = this.advarselString + "<li>Gatenummer</li>") : this.nyttLokale.controls['gateadresse'].setValue(gate + " " + gateNr);
    (poststed === -1) ? (this.advarselString = this.advarselString + "<li>Poststed</li>", this.nyttLokale.controls['poststed'].setValue("")) : this.nyttLokale.controls['poststed'].setValue(poststed);
    (postnr === -1) ? (this.advarselString = this.advarselString + "<li>Postnummer</li>", this.nyttLokale.controls['postnr'].setValue("")) : this.nyttLokale.controls['postnr'].setValue(postnr);




    //this.lat = place.geometry.location.lat()
    //this.lng = place.geometry.location.lng()

    this.lastKart();

    const inputElem = <HTMLInputElement>this.gateAd.nativeElement;
    inputElem.select();

   // console.log(place.geometry.location.lat())
   // console.log(this.nyttLokale)


    //Du må velge en fullstendig adress
    // this.setAddress.emit(place);
  }

  async extractFromAdress(components, type) {
    for (var i = 0; i < components.length; i++)
      for (var j = 0; j < components[i].types.length; j++)
        if (components[i].types[j] == type) return components[i].long_name;
    return -1;
  }

  adressType: string = "address";
  // @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;
  gjortSok: boolean = false;

  //lat: number = undefined;
  //lng: number = undefined;

  lastKart(): void {

    const myLatlng = { lat: this.nyttLokale.value.lat, lng: this.nyttLokale.value.lng };

    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
        center: { lat: 60, lng: 9 },
      }
    );

    if (this.nyttLokale.value.lat !== null) {
      map.setZoom(17);
      map.setCenter(myLatlng);


      const marker = new google.maps.Marker({
        position: myLatlng,
        map,
        title: "Click to zoom",
      });

      map.addListener("click", (event: any) => {

        marker.setPosition(event.latLng)
        this.nyttLokale.controls['lat'].setValue(event.latLng.lat());
        this.nyttLokale.controls['lng'].setValue(event.latLng.lng());
      });
    }

   
  }
  FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.minLength(4),

  ]);

}
