import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BrukerService } from 'src/environments/bruker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  leirer: any = [{leirnavn : "Laster..", id : 0}]
  visMenu = false;
  title = 'app';

  constructor(private router: Router, private http: BrukerService ) {
    /*if (window.location.pathname === "/plan-for-sjoforer" || window.location.pathname === "/desktopplan-for-sjoforer") {
      this.visMenu = false;
    }*/

    //router.events.subscribe((val) => {
      if (window.location.pathname === "/plan-for-sjoforer" ||
        window.location.pathname === "/desktopplan-for-sjoforer" ||
        window.location.pathname === "/transportplan" ||
        window.location.pathname === "/") {
          this.visMenu = false;
      }
      else{
        this.visMenu = true;
      }
    //})


  }





}
