import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { Observable, BehaviorSubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BrukerService } from '../../environments/bruker.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  TisExpanded = false;

  isExpandedSmal = false;
  TisExpandedSmal = false;

  //leirer: any[] = [];
  //gjeldeneLeirId = 0;

  isLoggedIn$: Observable<boolean>;

  endreLeir = this.fb.group({
    id: [0, Validators.required],   
    }
  )

  leirer: any;

  endreLeirAction(data: any) {

    if (data == -1) {
      //Send til endre leir-side
    }

    this.http.endreLeir(data).subscribe((res: any) => {
      //this.loggUt();
      this.router.navigateByUrl('/not-valid', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/'+window.location.pathname]);
      });
      window.location.reload();
    });
  }


  constructor(private router: Router, private auth: AuthGuard, private fb: FormBuilder, private http: BrukerService) {

  }

  ngOnInit() {

    this.isLoggedIn$ = this.auth.isLoggedIn;

    this.http.getAlleLeirer().subscribe((res: any) => {
      this.leirer = res;
    })
    /*this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
      
      if (window.location.pathname === "/plan-for-sjoforer" || window.location.pathname === "/desktopplan-for-sjoforer" || window.location.pathname === "/") {

      }
      else {
        /*this.http.getAlleLeirer().subscribe((res: any) => {
          this.leirer = res;
          this.endreLeir.patchValue({
            id: res[0].id
          })
        });*7
      }
    }
    })*/
    
  }

  collapse() {
    this.isExpanded = false;
    this.TisExpanded = false;
  }

  collapseSmal() {
    this.isExpandedSmal = false;
    this.TisExpandedSmal = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.TisExpanded = !this.isExpanded;
  }

  toggleSmal() {
    this.isExpandedSmal = !this.isExpandedSmal;
    this.TisExpandedSmal = !this.isExpandedSmal;
  }

  loggUt() {
    this.auth.logout();
    localStorage.removeItem('token');
    this.router.navigate([''])
    
  }
}
