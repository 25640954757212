import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(private http: HttpClient) {

  }

  getBankList() {
    return this.http.get(environment.apiBaseURI + '/TypeTpms');
  }
}
