import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { BrukerService } from '../../environments/bruker.service';

@Component({
  selector: 'app-registrer-admin',
  templateUrl: './registrer-admin.component.html',
  styleUrls: ['./registrer-admin.component.css']
})
export class RegistrerAdminComponent implements OnInit {
  public bekreftelsesmld: any;
  public tekstfarge: string = "black";

  constructor(private fb: FormBuilder, private http: BrukerService) { }

  formModel = this.fb.group({
    epost: ['', Validators.email],
    fornavn: ['', Validators.required],
    etternavn: ['', Validators.required],
    passord: this.fb.group({
      passord: ['', [Validators.required, Validators.minLength(8)]],
      bekreftPassord: ['', [Validators.required, Validators.minLength(8)]]
    }, { validator: this.comparePasswords })
    
  })


  comparePasswords(fb2: FormGroup) {
    let confirmPswrdCtrl = fb2.get('bekreftPassord');
    //passwordMismatch
    //confirmPswrdCtrl.errors={passwordMismatch:true}
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb2.get('passord').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }

  ngOnInit() {
  }


  sendForm() {
    var body = {
      Epost: this.formModel.value.epost,
      Passord: this.formModel.value.passord.passord,
      Etternavn: this.formModel.value.etternavn,
      Fornavn: this.formModel.value.fornavn,
    }

    this.http.post(body).subscribe(

      (res: any) => {
        if (res.succeeded) {
          this.formModel.reset();
          
          this.tekstfarge = "black"
          this.bekreftelsesmld = "Bruker registrert.";
          //this.toastr.success('New user created!', 'Registration successful.');
        } else {
          res.errors.forEach(element => {
            switch (element.code) {
              case 'DuplicateUserName':
                this.tekstfarge = "red"
                this.bekreftelsesmld = "Eposten er allerde i bruk, kunne ikke registrere brukeren.";
                break;

              default:
                this.tekstfarge = "red"
                this.bekreftelsesmld.error("Kunne ikke registrere bruker. Prøv igjen!");
                break;
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),

  ]);
}
