
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AktivitetService } from '../../environments/enviraktivitet/aktivitet.service';


@Component({
  selector: 'app-csv',
  templateUrl: './csv.component.html',
  styleUrls: ['./csv.component.css']
})
export class CsvComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CsvComponent>, private service: AktivitetService, @Inject(MAT_DIALOG_DATA) data
  ) {

  }

  bilde_data: any;
  csvNavn: any;
  csvUrl: any;
  knappNavn: string = "Velg fil.."
  filValgt: boolean = false;
  progresjon: number = 0;
  lasterOpp: boolean = false;
  responeOk: boolean = false;
  responeMld: string = "";
  feilMld: string = "";

  fileChange(event) {
    this.feilMld = "";
    this.responeMld = "";
    this.knappNavn = "Endre fil";

    this.filValgt = true;
    this.bilde_data = event.target.files[0];
    this.csvNavn = "Fil valgt: "+event.target.files[0].name;
    
    var reader = new FileReader();
    reader.onload = (event: any) => {
      //this.csvUrl = event.target.result;
    }
    reader.readAsDataURL(this.bilde_data);
  }

  lastOpp() {
    
    const formData: FormData = new FormData();
    this.lasterOpp = true;
    this.filValgt = false;

    formData.append("CSVfil", this.bilde_data);

    this.service.postCSV(formData).subscribe((res: any) => {
      if (res.status == 200) {
        this.knappNavn = "Velg fil..";
        this.lasterOpp = false;
        this.csvNavn = "";
        this.responeOk = true;
        //this.filValgt = false;
        this.feilMld = "";
        this.responeMld = res.body.message
      }
    },
      error => {
        this.knappNavn = "Velg fil..";
        this.lasterOpp = false;
        this.csvNavn = "";
        this.responeOk = true;
        //this.filValgt = false;
        this.feilMld = "Oisann! Det har skjedd en mulig feil i lesingen av filen. Oppdaert siden, og se om lesingen var vellykket. Hvis ikke, se at du har fulgt standarden for hvordan filen skal være bygget opp. Sjekk at blant annet 'deltakere' og 'leder'-kolonnen kun inneholder tall."
      
        console.log(error);
      }
      )
  }


  ngOnInit(): void {
  }

}
