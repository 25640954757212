import { TpmService } from 'src/environments/tpm.service';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormControl, FormBuilder, FormArray, Validators, FormGroup, NgForm } from '@angular/forms';
import { TypeService } from 'src/environments/type.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LokalerComponent } from '../lokaler/lokaler.component';
import { error } from 'protractor';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { klasseTpm } from './klasseTpm';

@Component({
  selector: 'tpm',
  templateUrl: './tpm.component.html',
  styleUrls: ['./tpm.component.css']
})



export class TpmComponent implements OnInit {
  showSpinner = true;
  duplikatVarsel = false;
  duplikatBussnr = "";

  openSnackBar(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white'];
    config.duration = 2000;
    this.snackBar.open(message, undefined, config);
  }

  bankAccountForms: FormArray = this.fb.array([]);
  KlasseTpm: klasseTpm;
  bankList = [];
  notification = null;



  constructor(private fb: FormBuilder,
    private bankService: TypeService,
    private service: TpmService, private snackBar: MatSnackBar) {


  }

  sjekkDuplikat(array: any[]): boolean {
    var valuesSoFar = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
      var value = array[i];
      console.log(value.bussnr)
      if (value.bussnr in valuesSoFar) {
        this.duplikatBussnr = value.bussnr;
        this.duplikatVarsel = true;
        return true;
      }
      valuesSoFar[value.bussnr] = true;
    }
    this.duplikatVarsel = false;
    return false;
  }


  ngOnInit() {


    this.bankService.getBankList()
      .subscribe(res => this.bankList = res as []);

    this.service.getBankAccountList().subscribe(

      res => {

        this.showSpinner = false;

          //generate formarray as per the data received from BankAccont table
          (res as []).forEach((bankAccount: any) => {
            this.bankAccountForms.push(this.fb.group({
              id: [bankAccount.id],
              bussnr: [bankAccount.bussnr, [Validators.min(1), Validators.required]],
              selskap: [bankAccount.selskap, Validators.required],
              kapasitetD1: [bankAccount.kapasitetD1, Validators.required],
              kapasitetD2: [bankAccount.kapasitetD2, Validators.required],
              kapasitetD3: [bankAccount.kapasitetD3, Validators.required],
              typeID: [bankAccount.typeID, Validators.min(1)],
              fornavn: [bankAccount.fornavn],
              etternavn: [bankAccount.etternavn],
              epost: [bankAccount.epost],
              telefonnr: [bankAccount.telefonnr],
              kommentar: [bankAccount.kommentar],
              leirId : [bankAccount.leirId],


            }));


          }

        );

        if (this.sjekkDuplikat(this.bankAccountForms.value) == true) {
          this.duplikatVarsel = true;
        }
        console.log(this.bankAccountForms.value);
      }
    );
  }


  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.min(1),

  ]);


  addBankAccountForm() {
    this.bankAccountForms.push(this.fb.group({
      id: [0],
      bussnr: ['', Validators.required],
      selskap: ['', Validators.required],
      kapasitet: ['', Validators.required],
      typeID: [0, Validators.min(1)],
      fornavn: ['', Validators.required],
      etternavn: ['', Validators.required],
      epost: ['', Validators.required],
      telefonnr: ['', Validators.required],
      kommentar: [''],
    }));
  }

  recordSubmit(fg: FormGroup) {

    if (fg.invalid === true) {

    }
    else
      this.service.putBankAccount(fg.value).subscribe(
        response => {
          if (response.ok) {
            this.sorter();
            this.showNotification('update');
            this.sjekkDuplikat(this.bankAccountForms.value);
          }
        },
        error => {
          this.showNotification('feil');
        });






    //sortering
    //setTimeout(() => {

    //}, 3000);
  }

  sorter() {
    var myArray = this.bankAccountForms.value;
    myArray.sort((obj1, obj2) => {
      if (obj1.bussnr > obj2.bussnr) {
        return 1;
      }

      if (obj1.bussnr < obj2.bussnr) {
        return -1;
      }
      return 0;
    });

    this.bankAccountForms.patchValue(myArray)
  }

  onDelete(id, i) {
    if (id == 0)
      this.bankAccountForms.removeAt(i);
    else if (confirm('Er du sikker på at du vil slette dette transportmiddelet?'))
      this.service.deleteBankAccount(id).subscribe(
        res => {
          this.bankAccountForms.removeAt(i);
          this.showNotification('delete');
        },
        error => {
          if (error.error.message === -1) {
            this.showNotification('tilHorendeAkt');
          }
        });
  }

  showNotification(category) {
    switch (category) {
      case 'insert':
        this.notification = { class: 'text-success', message: this.openSnackBar('Transportmiddelet er lagt til') };
        break;
      case 'update':
        this.notification = { class: 'text-primary', message: this.openSnackBar("Transportmiddelet er oppdatert.") };
        break;
      case 'delete':
        this.notification = { class: 'text-danger', message:  this.openSnackBar("Transportmiddelet er slettet.") };
        break;
      case 'feil':
        this.notification = { class: 'text-danger', message: this.openSnackBar("Ugyldig verdi, endring ikke lagret") };
        break;
      case 'tilHorendeAkt':
        this.notification = { class: 'text-danger', message: this.openSnackBar("Kan ikke slette. Transpormiddelet du forsøker å slette har tilhørende aktiviteter. Fjern disse aktivitetene fra planen.") };
        break;

      default:
        break;
    }
    setTimeout(() => {
      this.notification = null;
    }, 3000);
  }


}


