import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiline-tooltip',
  templateUrl: './multiline-tooltip.component.html',
  styleUrls: ['./multiline-tooltip.component.css']
})
export class MultilineTooltipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
