import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { EksporterService } from '../../environments/envireksporter/eksporter.service';
import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';


@Component({
  selector: 'storskjerm-eksporter',
  templateUrl: './storskjerm.component.html',
  styleUrls: ['./eksporter.component.css']
})
export class StorskjermComponent implements OnInit {

  constructor(private http: HttpClient, private hentData: EksporterService, ) {
  }

  tekstStorskjermDag1: string = "Laster inn dag 1..";
  tekstStorskjermDag2: string = "Laster inn dag 2..";
  tekstStorskjermDag3: string = "Laster inn dag 3..";

  @Input() datoDag1: string = "Dag 1";
  @Input() datoDag2: string = "Dag 2";
  @Input() datoDag3: string = "Dag 3";

  eposter: string;
  public tekstEposter: string = "trst"
  mailstring: string = "";
  //myHero = String(eposter[1]);

  //myHero = this.eposter[1];
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  copyMessage(dag:number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';

    if (dag === 1) {
      selBox.value = this.tekstStorskjermDag1;
    }
    if (dag === 2) {
      selBox.value = this.tekstStorskjermDag2;
    }
    if (dag === 3) {
      selBox.value = this.tekstStorskjermDag3;
    }
    
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  hentMailstring() {
    let mail;

    this.hentData.getStorskjerm(1).subscribe((data: any) =>
      mail = data.verdi
      
    );
      var today = new Date();
    today.setDate(today.getDate() + 1);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    timer(2000).subscribe(x => {
      let mailto = "mailto:";
      let subject = "STORSKJERMINFORMASJON - FOR: " + dd + '/' + mm + '/' + yyyy;
      this.mailstring = mailto + "?subject=" + subject + "&body=" + this.tekstStorskjermDag1;
    });
  }



   ngOnInit() {

    this.hentData.getStorskjerm(1).subscribe((data: any) =>
      this.tekstStorskjermDag1 = data.verdi
    );

    this.hentData.getStorskjerm(2).subscribe((data: any) =>
      this.tekstStorskjermDag2 = data.verdi
    );

    this.hentData.getStorskjerm(3).subscribe((data: any) =>
      this.tekstStorskjermDag3 = data.verdi
    );


     timer(0).subscribe(x => {
       //console.log(this.eposter[1])
       this.hentMailstring();
     })


  }


}
