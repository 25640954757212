import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AktivitetMTid } from '../plan/AktivitetMTid';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AktivitetService } from '../../environments/enviraktivitet/aktivitet.service';
import { MasterPlanComponent } from '../plan/master-plan.component';

@Component({
  providers: [MasterPlanComponent],
  selector: 'app-splitt',
  templateUrl: './splitt.component.html',
  styleUrls: ['./splitt.component.css']
})
export class SplittComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SplittComponent>, @Inject(MAT_DIALOG_DATA) akt: AktivitetMTid, public fb: FormBuilder, public http: AktivitetService, private dialog: MatDialog,
    private MasterPlanComponent: MasterPlanComponent) {

    this.akt = akt;
  }

  FormModule: any;

  public akt: AktivitetMTid;
  public rodTekst: string;
  public hovedtekst: string;
  public feilmelding: string;
  public lagrer: string;

  ngOnInit() {
    let restDeltakere = 0;
    let restLedere = 0;

    if (this.akt.aktivitet.antallDeltakere % 2 != 0) {
       restDeltakere = 1;
    }
    if (this.akt.aktivitet.antallLedere % 2 != 0) {
       restLedere = 1;
    }

    

    this.FormModule = this.fb.group({
      id: this.akt.aktivitet.antallDeltakere.id,
      nyttNavnG1: [this.akt.aktivitet.navn + " - G1"/*, Validators.maxLength(12)*/],
      nyttNavnG2: [this.akt.aktivitet.navn + " - G2"/*, Validators.maxLength(12)*/],
      antallDeltakereG1: [Math.round(this.akt.aktivitet.antallDeltakere / 2) - restDeltakere] ,
      antallDeltakereG2: Math.round(this.akt.aktivitet.antallDeltakere / 2),
      antallLedereG1: Math.round(this.akt.aktivitet.antallLedere / 2) - restLedere,
      antallLedereG2: Math.round(this.akt.aktivitet.antallLedere / 2),
    })
  }

  FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(10),

  ]);
  

  valideringskontroll() : boolean {



    if (!(this.FormModule.value.antallDeltakereG1 + this.FormModule.value.antallDeltakereG2 === this.akt.aktivitet.antallDeltakere)) {
      this.feilmelding = "Rest må våre lik null.";
      return false;
    }

    if (!(this.FormModule.value.antallLedereG1 + this.FormModule.value.antallLedereG2 === this.akt.aktivitet.antallLedere)) {
      this.feilmelding = "Rest må våre lik null.";
      return false;
    }

    else {
      return true;
    }
  }

  avbryt() {
    this.dialogRef.close;
  }


  lagre() {
    this.feilmelding = "";
    if (this.valideringskontroll() == true) {

      this.lagrer = "Lagrer..."
      var body = {
        id: this.akt.aktivitet.id,
        nyttNavnG1: this.FormModule.value.nyttNavnG1,
        nyttNavnG2: this.FormModule.value.nyttNavnG2,
        antallDeltakereG1: this.FormModule.value.antallDeltakereG1,
        antallDeltakereG2: this.FormModule.value.antallDeltakereG2,
        antallLedereG1: this.FormModule.value.antallLedereG1,
        antallLedereG2: this.FormModule.value.antallLedereG2
      }

        this.http.postSplitt(body).subscribe(
          (res: any) => {
            console.log(res);
            this.MasterPlanComponent.lastInnPaaNytt();
            this.dialogRef.close(false);
            
          },
          err => {
            console.log(err);
          }
      );
  }
  }
}
