import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AktivitetMTid } from 'src/app/plan/AktivitetMTid';

@Component({
  selector: 'app-utskrift',
  templateUrl: './utskrift.component.html',
  styleUrls: ['./utskrift.component.css']
})
export class UtskriftComponent implements OnInit {


    @Input() avganger: any;
    @Input() dato: string;
    @Input() leir: string

    constructor() { }

    ngOnInit(): void {
    
    }

        
        
    

}
