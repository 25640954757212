import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {

  }

  get isLoggedIn() {
    if (localStorage.getItem('token') != null) {
      this.loggedIn.next(true);
      return this.loggedIn.asObservable();
    }

    return this.loggedIn.asObservable();
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') != null) {
      //this.loggedIn.next(true);
      return true;
    }
    else {
      //this.loggedIn.next(false);
      this.router.navigate(['']);
      return false;
    }
  }

  login() {
      this.loggedIn.next(true);
    }
  
  logout() {
    this.loggedIn.next(false);
    this.router.navigate(['']);
  }
  
}
