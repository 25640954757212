import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AktivitetService {

  constructor(private http: HttpClient) { }

  postAktivitet(formData) {
    return this.http.post(environment.apiBaseURI + '/aktivitet', formData, { observe: 'response' });
  }

  postCSV(file) {
    return this.http.post(environment.apiBaseURI + '/Aktivitet/CSV', file, { observe: 'response' })
  }

  putLokale(formData) {
    return this.http.put(environment.apiBaseURI + '/aktivitet/' + formData.id, formData, { observe: 'response' });
  }

  slettLokale(id) {
    return this.http.delete(environment.apiBaseURI + '/aktivitet/' + id, { observe: 'response' });
  }

  getAktivitet() {
    return this.http.get(environment.apiBaseURI + '/aktivitet');
  }

  postSplitt(Body) {
    return this.http.post(environment.apiBaseURI + '/aktivitet/SplittAktivitet', Body, { responseType: 'text' })
  }
}
