import { Component, OnInit, Input, ChangeDetectionStrategy, Output, ChangeDetectorRef, EventEmitter, SimpleChanges } from '@angular/core';
import { AktivitetMTid } from './AktivitetMTid';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PlanService } from '../../environments/plan.service';
import { catchError, retry, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SplittComponent } from '../splitt/splitt.component';
import { MasterPlanComponent } from './master-plan.component';
import { RouterModule, Router } from '@angular/router';
import { Lokale, Reisetid } from './Reisetid';
import { NgZone } from '@angular/core';

/*
Mangler, gjøre el 1 auto hvis el 0 flyttes
Oppdatere resten av tidene når avgangstid for el 0 endres


*/
@Component({
  selector: 'app-reisebolk',
  templateUrl: './reisebolk.component.html',
  styleUrls: ['./reisebolk.css'],
  //styleUrls: ['./plan.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReisebolkComponent implements OnInit {


  @Input() runde: AktivitetMTid[];
  @Input() dag: number;
  @Input() kapasitet: number;
  @Input() avreiseEn: string;
  @Input() avreiseTo: string;
  @Input() GodkjentReisegruppe = [];
  @Input() id: number;
  @Input() rundeTall: number;
  @Input() rundenr: number;
  @Input() tr: number;
  @Input() index: number;
  @Input() reisetider: Reisetid[];
  @Input() LokaleIngenTransportId: number;
  @Input() tilbakeTidR2T1: number; //only for r2t1
  @Output() tilbakeTidR2T1Out = new EventEmitter<number>();
  @Input() tilbakeTidR1T1: number; //only for r1t1
  @Output() tilbakeTidR1T1Out = new EventEmitter<number>();

  tilbakeKl : string = "-"



  tidligstAvreiseR2: string = "";
  numbers = [1, 2, 3, 4, 5, 6, 7, 8];
  stopDelay = 240 //time that are added for each stop (seconds)

  public antallPaaBuss: number = 0;
  //public kapasitet: number = 55;
  fontfarge: string;

  constructor(public cd: ChangeDetectorRef, private snackBar: MatSnackBar, private service: PlanService, private dialog: MatDialog, private router: Router) { }




  ngOnInit() {
    this.antallPaaBuss = this.runde.reduce(function (prev, cur) {
      return prev + cur.antall;
    }, 0);

    this.sorterOgBeregnTid();
  }


  ventPaaRunde(){
  }

  onBlurMethod(obj: AktivitetMTid) {

    //this.sorter();
    this.beregnTidInit()
    this.service.putAvreisetid(obj).subscribe(
      response => {

        if (response.ok) {
          //this.sorter();
          this.lagret("Avreisetid oppdatert");

        }
        if (!response.ok) {
          this.lagret("Ukjent feil, ikke lagret");
        }

        retry(3),
          catchError(this.handleError)
      });
  }

  oppdaterAktivtetMTid(obj_inn : any){
    var obj = JSON.parse(JSON.stringify(obj_inn));

    //Hvis auto fra før
    if(obj.auto === true){
      obj.avreise = null
    }

    this.service.putAvreisetid(obj).subscribe(
      response => {

        if (response.ok) {
          //TODO kan fjernes etterhvert
          if(obj.auto == true)
            this.lagret("Endret til automatisk tid");
          else
            this.lagret("Endret til manuell tid");
        }
        if (!response.ok) {
          this.lagret("Ukjent feil, ikke lagret");
        }

        retry(3),
          catchError(this.handleError)
      });
  }



  kontrollerLister(listelengde: number, liste: AktivitetMTid[]): number {
    let nyAntallverdi: number = 0;
    if (listelengde !== this.kapasitet) {
      //this.cd.markForCheck();
      nyAntallverdi = liste.reduce(function (prev, cur) {
        return prev + cur.antall;

      }, 0);
    }
    return nyAntallverdi;
  }

  fargeKontroll(passasjerer: number): string {
    let fontfarge: string;
    if (passasjerer > this.kapasitet) {
      fontfarge = "red";
    }
    else {
      fontfarge = "green";
    }
    return fontfarge;
  }

  dialogRef: MatDialogRef<SplittComponent>;
  @Output() messageEvent = new EventEmitter<string>();


  splittAktivitet(akt: AktivitetMTid) {
    this.dialogRef = this.dialog.open(SplittComponent, {
      disableClose: false,
      autoFocus: true,
      maxWidth : "80vw",
      maxHeight : "90vh",
      minWidth : "80vw",
      minHeight : "90vh",
      data: akt
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
      //Må fikse å kunne oppdatere, se https://stackoverflow.com/questions/37587732/how-to-call-another-components-function-in-angular2
      this.messageEvent.emit("ok");

      this.dialogRef = null;
    });
  }

  infoBar(mld: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white'];
    config.duration = 4000;
    this.snackBar.open(mld, undefined, config);
  }

  lagret(mld: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white-plan'];
    config.duration = 1300;
    config.horizontalPosition = 'left';
    this.snackBar.open(mld, undefined, config);
  }

  /*
  Proberly not in use
  ngDoCheck2() {
    let nyAntallverdi: number = 0;
    this.runde.forEach((data: AktivitetMTid) => {

      //this.cd.markForCheck();
    })
  }*/

  changeHandler() {


    /*this.runde.forEach((obj: any) => {
      if (data.id === obj.id) {
        obj.avgang === data.avreise;
      }
    })*/
  }



  ngDoCheck() {
    this.antallPaaBuss = this.kontrollerLister(this.runde.length, this.runde);
    this.fontfarge = this.fargeKontroll(this.antallPaaBuss);
    this.oppdaterTilbakeTid(this.runde);
    this.oppdaterTidligtsAvreise();
    //console.log("detect changes");
  }

  drop(event: CdkDragDrop<AktivitetMTid[]>) {

    var element: any = event.previousContainer.data[event.previousIndex];

    //Henter informasjon om forrige dag retning
    var forrigeRunde = event.previousContainer.data[event.previousIndex]['rundeTall'];

    //Samme plass ogg samme liste
    if (event.currentIndex == event.previousIndex && event.previousContainer == event.container) {
      return;
    }

    //bug som gjør at aktMTid noen ganger kan legge seg foran enda-AktMTiden som ligger øverst
    if (event.currentIndex == 0 && element.turRunde == 11 || event.currentIndex == 0 && element.turRunde == 12) {
      return;
    }

    //Er det samme retning?
    if (forrigeRunde == this.rundeTall) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      element.runde = this.rundenr;

      let formData = {
        AktivitetMTid: element,
        turRunde: this.tr,
        tilAvgang: event.container.id,
        nyIndex: event.currentIndex
      }

      this.sendData(formData, event);
      //this.sorter();
      this.oppdaterTiderOnDrop(event);
    }



    else {
      this.infoBar("Aktiviteten du forsøkte å flytte tilhører ikke denne retningen. Endringen ble ikke lagret.");
    }
  }


  public sortPredicate(index: number, item: CdkDrag<AktivitetMTid>) {

    const aktMTid: AktivitetMTid = item.data;


    //runde 1
    if(aktMTid.rundeTall == 1){
      if(index == 0){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      return true;
    }


    //console.log(item.data);
    //return !item.data.endePaaRute;
  }




  oppdaterTiderOnDrop(event : CdkDragDrop<AktivitetMTid[]>){
    /*
      Henter informasjon fra tid-matrise

      Må også oppdatere verdiene fra der elemntet kom fra

    */
    var aktMTidItems_current = event.container.data
    var aktMtidItems_prev = event.previousContainer.data
    var moved_item_current = event.container.data[event.currentIndex]
    var moved_item_prev = event.previousContainer.data[event.previousIndex]




    //Er en utreise runde 1
    /*if(this.tr == 11 && moved_item_current.auto == true){
      moved_item_current["avreise"] = this.avreiseEn
    }
    //Er en utreise runde 2
    else if(this.tr == 12 && moved_item_current.auto == true){
      moved_item_current["avreise"] = this.avreiseTo
    }
    //Manuell tid bolk 11
    else if(this.tr == 11 && moved_item_current.auto == false){
      console.log("tr 11")
      return;
    }
    //Manuell tid bolk 12
    else if(this.tr == 12 && moved_item_current.auto == false){
      console.log("tr 11")
      return;
    }*/
    //else{

      //console.log(this.tr)

      //const utganspunkt = event.container.data
      //var a = utganspunkt.split(":")
      //var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
      /*console.log(aktMTidItems_current[0])  */
      //console.log(aktMTidItems_current[0])

      //Sjekker om elementet som ble flyttet var det eneste som eksiterte i forrig liste
      /*if(aktMtidItems_prev.length < 1){
        var moved_item_prev = event.previousContainer.data[event.previousIndex]
        console.log("Flyttet item var i posisjon null og enseste i forrige liste")
        if(aktMtidItems_prev[0]["id"] === moved_item_prev["id"]){

          //aktMtidItems_prev[1].auto =
          //Send api og oppdater tiden til item i posisjon 1 i prev list
          //this.oppdaterAktivtetMTid(moved_item_current)
        }
      }*/

      //Hvis det var et inernt bytte i bolken


      if(this.tr == moved_item_current.turRunde && event.previousContainer.id === event.container.id){

        //Hvis elementet ble flyttet øverst
        if(event.currentIndex == 0){
          console.log("Flyttet øverst i samme liste")
          moved_item_current.auto = false;
          aktMTidItems_current[1].auto = true;

          this.oppdaterAktivtetMTid(moved_item_current)
          this.oppdaterAktivtetMTid(aktMTidItems_current[1])
        }
        //Hvis det var i posisjon null
        if(event.previousIndex == 0){
          console.log("Var i posisjon null i denne listen")
          moved_item_prev.auto = false;
          aktMTidItems_current[1].auto = true;

          this.oppdaterAktivtetMTid(moved_item_prev)
          this.oppdaterAktivtetMTid(aktMTidItems_current[1])
        }

        moved_item_current.turRunde = this.tr;


      }
      //Bytte til en annen bolk
      else{

        moved_item_current.turRunde = this.tr;

        //Oppdaterer tur/runde

        //Hvis øverst i ny liste
        if(event.currentIndex == 0){
          if(moved_item_current.auto == true){
            console.log("Flyttet øverst i ny liste")
            moved_item_current.auto = false
            //Setter elementet som var øverst til ikke-auto
            this.oppdaterAktivtetMTid(moved_item_current)
          }
          if(aktMTidItems_current.length > 1){
            aktMTidItems_current[1].auto = true
            this.oppdaterAktivtetMTid(aktMTidItems_current[1])
          }
          //Hvis den også var øverst i forrige liste
          if(event.previousIndex == 0 && aktMtidItems_prev.length > 0){
            aktMtidItems_prev[0].auto = false
            this.oppdaterAktivtetMTid(aktMtidItems_prev[0])
          }
        }

        //Hvis det flyttede elementet var øverst i forrige liste
        else if(event.previousIndex == 0){
          //Elementet som ligger i posisjon null i forrige liste, må nå bli auto
          if(aktMtidItems_prev.length != 0){
            console.log("Var øverst i forrige liste")
            aktMtidItems_prev[0].auto = false;
            this.oppdaterAktivtetMTid(aktMtidItems_prev[0])
          }
          //Oppdaterer til auto, med mindre det havnet øverst i ny liste
          if(event.currentIndex != 0){
            moved_item_current.auto = true
            //Send api og oppdater tiden til item i posisjon 1 i prev list
            this.oppdaterAktivtetMTid(moved_item_current)
          }
        }




        //Alene i ny liste
        else if(aktMTidItems_current.length == 1){
          if(moved_item_current.auto == true){
           // console.log("Alene i ny liste")
            moved_item_current.auto = false
            this.oppdaterAktivtetMTid(moved_item_current)
          }
        }

        //Havner et sted annet enn øverst listen
        else{
          //console.log("Vanlig bytte, blir til auto")
          if(moved_item_current.auto == false){
            moved_item_current.auto = true
            this.oppdaterAktivtetMTid(moved_item_current)
          }
        }
      }

      //Hvis det flyttede elementet havner øverst i ny liste
      /*if(event.currentIndex == 0){
        console.log("Flyttet item er øverst i listen element ble flyttet til")
        moved_item_current.auto = false
        this.oppdaterAktivtetMTid(moved_item_current)

        //Sørger også for at item-et som tidligere lå øverst nå blir auto
        if(aktMTidItems_current.length > 1){
          aktMTidItems_current[1].auto = true
          this.oppdaterAktivtetMTid(aktMTidItems_current[1])
        }

        //Send api og oppdater tiden til item i posisjon 1 i prev list

      }*/

      //Gammel liste



      //Går gjennom den nye listen
      for(let i = 0; i < aktMTidItems_current.length-1; i++){
        //Hvis lokale er likt det forrige
        if(aktMTidItems_current[i].aktivitet["lokaleD" + this.dag].id == aktMTidItems_current[i+1].aktivitet["lokaleD" + this.dag].id){
          aktMTidItems_current[i+1].avreise = aktMTidItems_current[i].avreise;
        }
        else{
          this.reisetider.forEach((obj) => {
            //Hvis i sitt lokale er likt i-sitt neste lokale
            if((aktMTidItems_current[i].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.fraLokaleId/*)*/ && aktMTidItems_current[i+1].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.tilLokaleId/*)*/) || (aktMTidItems_current[i].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.tilLokaleId/*)*/ && aktMTidItems_current[i+1].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.fraLokaleId/*)*/)){
              var a = aktMTidItems_current[i].avreise.split(":")
              var fraSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
              if(aktMTidItems_current[i+1].auto == true)
                aktMTidItems_current[i+1].avreise = (new Date((fraSeconds + obj.tidMs + this.stopDelay) * 1000).toISOString().substr(11, 5))
            }

          })
        }
      }

      //Går gjennom den gamle listen
      for(let i = 0; i < aktMtidItems_prev.length-1; i++){
        //Hvis lokale er likt det forrige
        if(aktMtidItems_prev[i].aktivitet["lokaleD" + this.dag].id == aktMtidItems_prev[i+1].aktivitet["lokaleD" + this.dag].id){
          aktMtidItems_prev[i+1].avreise = aktMtidItems_prev[i].avreise;
        }
        else{
          this.reisetider.forEach((obj) => {
            //Hvis i sitt lokale er likt i-sitt neste lokale
            if((aktMtidItems_prev[i].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.fraLokaleId/*)*/ && aktMtidItems_prev[i+1].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.tilLokaleId/*)*/) || (aktMtidItems_prev[i].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.tilLokaleId/*)*/ && aktMtidItems_prev[i+1].aktivitet["lokaleD" + this.dag].id === /*parseInt(*/obj.fraLokaleId/*)*/)){
              var a = aktMtidItems_prev[i].avreise.split(":")
              var fraSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
              if(aktMtidItems_prev[i+1].auto == true)
              aktMtidItems_prev[i+1].avreise = (new Date((fraSeconds + obj.tidMs + this.stopDelay) * 1000).toISOString().substr(11, 5))
            }

          })
        }
     // }

  }
  //this.oppdaterTilbakeTid(aktMTidItems_current);

}

/*
  Brukes kun i
*/
oppdaterTidligtsAvreise(){



  if(this.tr == 12){
    this.tidligstAvreiseR2 == "";
    //kommentert ut da det er usikkert om det best at tidligst-tid vises øverst i R1T2
    //this.tidligstAvreiseR2 = (new Date(((this.tilbakeTidR1T1)) * 1000).toISOString().substr(11, 5)) + " (tidligst)"
  }
  //hvis runden ikke er tom
  if(this.runde.length > 0){
    const lokIngTraId = this.LokaleIngenTransportId;
    const tilLokaleId = this.runde[0].aktivitet["lokaleD" + this.dag].id;

    this.reisetider.forEach((rt1 : Reisetid) => {
      if((rt1.fraLokaleId == tilLokaleId && rt1.tilLokaleId == lokIngTraId) || (rt1.tilLokaleId == tilLokaleId && rt1.fraLokaleId == lokIngTraId)){
        this.tidligstAvreiseR2 = (new Date(((rt1.tidMs + this.tilbakeTidR2T1 + this.stopDelay)) * 1000).toISOString().substr(11, 5)) + " (tidligst)"
          //console.log("Reisetid er funnet for forste avreise, tilbake i 12 er: "+this.tilbakeTidR2T1);
      }
    })
  }
  //hvis runden er tom
  else{
    this.tidligstAvreiseR2 = "";
  }



}

oppdaterTilbakeTid(aktMTidItems_current : AktivitetMTid[]){


  if((this.rundeTall == 2 && aktMTidItems_current.length > 0) || (aktMTidItems_current.length > 1 && this.rundeTall == 1)){
    //Finner id til lokale til ingen transport
    let lokaleTilIngenTpmId = this.LokaleIngenTransportId;

    const sisteAktMTid: AktivitetMTid = aktMTidItems_current[aktMTidItems_current.length-1];

    this.reisetider.forEach((obj : Reisetid) => {
      if(obj.fraLokaleId === lokaleTilIngenTpmId && obj.tilLokaleId === sisteAktMTid.aktivitet["lokaleD" + this.dag].id || obj.tilLokaleId === lokaleTilIngenTpmId && obj.fraLokaleId === sisteAktMTid.aktivitet["lokaleD" + this.dag].id){
        if(sisteAktMTid.avreise === null || sisteAktMTid.avreise === ""){
          this.tilbakeKl = "?";
        }
        else{
          var a = sisteAktMTid.avreise.split(":")
          var fraSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
          this.tilbakeKl = (new Date((fraSeconds + obj.tidMs) * 1000).toISOString().substr(11, 5)) + " (tilbake)"

          if(this.tr == 21){
            this.tilbakeTidR2T1Out.emit((fraSeconds + obj.tidMs));
          }
          if(this.tr == 11){
            this.tilbakeTidR1T1Out.emit((fraSeconds + obj.tidMs));
          }




        }
      }
    })
  }
  else{
    this.tilbakeKl = ""
  }



}






  sendData(formData_inn: any, event: CdkDragDrop<AktivitetMTid[]>) {
    var formData = JSON.parse(JSON.stringify(formData_inn));
    //Hvis auto
    if(formData.AktivitetMTid.auto == true){
      formData.AktivitetMTid.avreise = null
    }

    this.service.putAktivitMTid(formData).subscribe(
      response => {
        if (response.ok) {
          if (event.container == event.previousContainer)
            this.lagret("Rekkefølge oppdatert");
          else
            this.lagret("Endring lagret");
        }
        formData
        if (!response.ok) {
          this.lagret("Ukjent feil, ikke lagret");
        }
        retry(3),
          catchError(this.handleError)
      });
  }

  endreAuto(item : any, tvingTilManuell : boolean){
    console.log(tvingTilManuell);
    if(tvingTilManuell){
        item.auto = false;
        item.avreise = "14:00";
    }
    this.oppdaterAktivtetMTid(item)
    //this.beregnTidInit();
  }


  sorterOgBeregnTid(){
    this.runde.sort(function compare(a, b) {
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    })

    this.runde = this.beregnTidInit();
    this.oppdaterTilbakeTid(this.runde)
    //Oppdater tidlig avreise hvis reiebolken er 22
    if(this.tr == 22 || this.tr == 12){
      this.oppdaterTidligtsAvreise()
    }
    /*
    this.beregnTidInit(function() {


      console.log(this.runde);
      //console.log(this.runde)
      //console.log("sortert")
      //this.oppdaterTilbakeTid(this.runde)
      //console.log('huzzah, I\'m done!');
    }); */



  }

  beregnTidInit() {
    //Går gjennom den nye listen
    for(let i = 0; i < this.runde.length-1; i++){
      /*if(this.tr == 11 && this.runde[i].auto == true){
        this.runde[i]["avreise"] = "21:00";
      }
      else if(this.tr == 12 && this.runde[i].auto == true){
        this.runde[i]["avreise"] = "21:00";
      }*/
      /*if(this.tr == 11 && this.runde[i].auto == false){
        //return;
      }
      else if(this.tr == 12 && this.runde[i].auto == false){
        //return;
      }*/
      //else{
        //console.log(aktMTidItems_current[i].avreise+", "+aktMTidItems_current[i+1].avreise)
        //Hvis to like etter hverandre
        if(this.runde[i].avreise !== null && this.runde[i].avreise !== ""){
            if(this.runde[i].aktivitet["lokaleD" + this.dag].id == this.runde[i+1].aktivitet["lokaleD" + this.dag].id){

            var a = this.runde[i].avreise.split(":")
            var fraSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
            this.runde[i+1].avreise = (new Date((fraSeconds) * 1000).toISOString().slice(11, 16))
            }
            //Hvis de ikke er like
            else{
            this.reisetider.forEach((obj) => {
                //Hvis i sitt lokale er likt i-sitt neste lokale
                if((this.runde[i].aktivitet["lokaleD" + this.dag].id === obj.fraLokaleId && this.runde[i+1].aktivitet["lokaleD" + this.dag].id === obj.tilLokaleId) || (this.runde[i].aktivitet["lokaleD" + this.dag].id === obj.tilLokaleId && this.runde[i+1].aktivitet["lokaleD" + this.dag].id === obj.fraLokaleId)){
                var a = this.runde[i].avreise.split(":")
                var fraSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
                //console.log(this.runde[i+1])
                if(this.runde[i+1].auto == true)
                    this.runde[i+1].avreise = (new Date((fraSeconds + obj.tidMs + this.stopDelay) * 1000).toISOString().substr(11, 5))
                }
                })
            }
        }
        //}

    }
    return this.runde
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

}

