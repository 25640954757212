import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BrukerService } from '../../environments/bruker.service';
import { PlanService } from '../../environments/plan.service';
import { Leir } from '../plan/master-plan.component';

@Component({
  selector: 'app-publiser-valg',
  templateUrl: './publiser-valg.component.html',
  styleUrls: ['./publiser-valg.component.css']
})
export class PubliserValgComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PubliserValgComponent>, private fb: FormBuilder, private http: BrukerService, private service: PlanService) { }

  bekreftMld: string;

  url = "";
  leirId = "";
  @Input() datoDag1: string = "Dag 1";
  @Input() datoDag2: string = "Dag 2";
  @Input() datoDag3: string = "Dag 3";


  formModel = this.fb.group({
    type: ["1", Validators.required],
    dag: ["1", Validators.required],
  })

  


  ngOnInit(): void {
    this.http.getBrukerprofil().subscribe((res: any) => {
      this.leirId = res.leir.id
    });
  }

  generer() {
    this.bekreftMld = ""
    if (this.leirId === "") {
      console.log(this.formModel.value);
      this.bekreftMld = "Prøv på nytt!"
    }

    //type 1 er mobilversjon
    let dato = this.datoDag1;
    if (this.formModel.value.dag === "2") {
      dato = this.datoDag2;
    }
    if (this.formModel.value.dag === "3") {
      dato = this.datoDag3;
    }


    if (this.formModel.value.type === "1") {
      console.log(this.formModel.value);
      this.url = window.location.origin + "/plan-for-sjoforer?dag=" + this.formModel.value.dag + "&leirid=" + this.leirId;
    }
    else {
      this.url = window.location.origin + "/desktopplan-for-sjoforer?dag=" + this.formModel.value.dag + "&leirid=" + this.leirId;

    }

    //this.bekreftMld = "Link med dato " + this.formModel.value.dato + " for dag " + this.formModel.value.dag + " er kopiert til utklippstavelen."

    
  }

}
