import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeService } from 'src/environments/type.service';
import { TpmService } from 'src/environments/tpm.service';
import {FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { timer } from 'rxjs';




@Component({
  selector: 'app-fetch-data',
  templateUrl: './nyTpm.component.html',
  styleUrls: ['./nyTpm.component.css']
})
export class NyTpm{

  listeType = [];
  nyTpm: FormGroup;
  ulikKapasitet = false;

  isChecked = true;


 
  id: FormControl;
  bussnr: FormControl;
  selskap: FormControl;
  typeID: FormControl;
  kapasitetD1: FormControl;
  kapasitetD2: FormControl;
  kapasitetD3: FormControl;
  kommentar: FormControl;
  //Sjofør
  fornavn: FormControl;
  etternavn: FormControl;
  epost: FormControl;
  telefonnr: FormControl;

  showSpinner = false;


  constructor(private bankService: TypeService, private fb: FormBuilder, private service: TpmService, private router: Router) {
    this.nyTpm = this.fb.group({
      published: true,
      credentials: this.fb.array([]),
    });
  }
  

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.bankService.getBankList()
      .subscribe(res => this.listeType = res as []);
  }



  createFormControls() {
    this.id = new FormControl(0);
    this.bussnr = new FormControl('', Validators.required);
    this.selskap = new FormControl('', Validators.required);
    this.typeID = new FormControl('1', Validators.required);
    this.kapasitetD1 = new FormControl('', Validators.required);
    this.kapasitetD2 = new FormControl('', Validators.required);
    this.kapasitetD3 = new FormControl('', Validators.required);
    this.kommentar = new FormControl('');

    //Sjofør
    this.fornavn = new FormControl('');
    this.etternavn = new FormControl('');
    this.epost = new FormControl('');
    this.telefonnr = new FormControl('');
  }

  createForm() {
    this.nyTpm = new FormGroup({
      id: this.id,
      bussnr: this.bussnr,
      selskap: this.selskap,
      typeID: this.typeID,
      kommentar: this.kommentar,

      kapasitetD1: this.kapasitetD1,
      kapasitetD2: this.kapasitetD2,
      kapasitetD3: this.kapasitetD3,

      //Sjofør
      fornavn: this.fornavn,
      etternavn: this.etternavn,
      epost: this.epost,
      telefonnr: this.telefonnr,
    });
  }

  avbryt() {
    if (!this.nyTpm.valid) {
      this.router.navigate(['/tpm']);
    }
  }

 
  sendForm() {
    this.showSpinner = true
    this.service.postBankAccount(this.nyTpm.value).subscribe(
      data => {
        console.log("Lagret til server: ", data)
        this.router.navigate(['/tpm']);
      },
      error => console.error("couldn't post because", error)
      //this._router.navigate(['/users', { id: userId }]);
    );
  }

  ulikKapasitetEvent(event: any) {
    console.log(event);
    this.ulikKapasitet = true;

  }


  FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.minLength(8),

  ]);
  
}


