
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormControl, FormBuilder, FormArray, Validators, FormGroup, NgForm } from '@angular/forms';
import { LokaleService } from 'src/environments/envirlokale/lokale.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { error } from 'protractor';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { createNull } from 'typescript';
import { AdresseComponent } from './adresse/adresse.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lokaler',
  templateUrl: './lokaler.component.html',
  styleUrls: ['./lokaler.component.css']
})
export class LokalerComponent {

  openSnackBar(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white'];
    config.duration = 3000;
    this.snackBar.open(message, undefined, config);
  }

  @ViewChild('form')
  form: NgForm;


  bankAccountForms: FormArray = this.fb.array([]);
  bankList = [];
  notification = null;

  dialogRef: MatDialogRef<AdresseComponent>;

  opneAdresse(fg) {
    this.dialogRef = this.dialog.open(AdresseComponent, {
      disableClose: true,
      autoFocus: false,
      data: fg,
      minWidth: '90vw',
      minHeight: '90vh',
      maxWidth: '90vw',
      maxHeight: '90vh'

      //maxWidth: '100vw',

    });

    this.dialogRef.afterClosed().subscribe(fg => {
      console.log(fg)
      if(fg !== "avbryt")
        this.recordSubmit(fg);
    })
  }

  constructor(private fb: FormBuilder, private service: LokaleService, private snackBar: MatSnackBar, private dialog: MatDialog) {
  }

  showSpinner = true;



  ngOnInit() {

    this.service.getLokale().subscribe(
      res => {
        this.showSpinner = false;
          (res as []).forEach((bankAccount: any) => {
            console.log(bankAccount.lokaleTilIngenTransport);
            if (bankAccount.lokaleTilIngenTransport === false) {
              this.bankAccountForms.push(this.fb.group({
                id: [bankAccount.id],
                navn: [bankAccount.navn, Validators.required],
                farge: [bankAccount.farge, Validators.required],
                omraade: [bankAccount.omraade, Validators.required],
                kapasitet: [bankAccount.kapasitet, Validators.required],
                leirId : [bankAccount.leirId, Validators.required],


                kommentar: [bankAccount.kommentar, Validators.min(1)],
                gateadresse: [bankAccount.gateadresse, Validators.required],
                postnr: [bankAccount.postnr, Validators.required],
                poststed: [bankAccount.poststed, Validators.required],

                lat: [bankAccount.lat, Validators.required],
                lng: [bankAccount.lng, Validators.required],


              }));
            };
          });
        }
    )
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,

  ]);

  recordSubmit(fg: FormGroup) {
    if (fg.value.id == 0)
      this.service.postLokale(fg.value).subscribe(
        (res: any) => {
          fg.patchValue({ id: res.id });
          this.showNotification('insert');
        });
    else
      this.service.putLokale(fg.value).subscribe(
        (res: any) => {
          this.showNotification('update');
        });
  }

  onDelete(id, i) {
    if (id == 0)
      this.bankAccountForms.removeAt(i);
    else if (confirm('Er du sikker på at du vil slette dette lokalet?'))
      this.service.slettLokale(id).subscribe(
        res => {
          this.bankAccountForms.removeAt(i);
          this.showNotification('delete');
        }, error => this.showNotification('feil') )
  }

  showNotification(category) {
    switch (category) {
      case 'insert':
        this.notification = { class: 'text-success', message: this.openSnackBar('Lokalet er lagt til') };
        break;
      case 'update':
        this.notification = { class: 'text-primary', message: this.openSnackBar("Lokalet er oppdatert.") };
        break;
      case 'delete':
        this.notification = { class: 'text-danger', message: this.openSnackBar("Lokalet er slettet.") };
        break;
      case 'feil':
        this.notification = { class: 'text-danger', message: this.openSnackBar("Lokalet har tilhørende aktiviteter, og kan ikke slettes.") };
        break;
    }
    setTimeout(() => {
      this.notification = null;
    }, 3000);
  }

  colors = [
    { name: 'Svart', value: 'black' },
    { name: 'Grå', value: 'gray' },
    { name: 'Mørkeblå', value: 'darkblue' },
    { name: 'Rosa', value: 'deeppink' },
    { name: 'Vinrød', value: 'darkred' },
    { name: 'Grønn', value: 'green' },
    { name: 'Oransje', value: 'orange' },
  ];

}
export interface TpmList {
  id: string;
  bussnr: number;
  selskap: string;
  kapasitet: number;
  typeID: string;
  fornavn: string;
  etternavn: string;
  telefonnr: string;
  epost: string;
  kommentar: string;
}



