import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vis-transportplan',
  templateUrl: './vis-transportplan.component.html',
  styleUrls: ['./vis-transportplan.component.css']
})
export class VisTransportplanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
