import { Component, OnInit, Input } from '@angular/core';
import { AktivitetMTid } from './AktivitetMTid';
import { Reisebolk } from './Reisebolk';
import { PlanService } from '../../environments/plan.service';
import { timer } from 'rxjs';
import { map } from 'jquery';
import { cloneDeep } from 'lodash';
import { OmProsjektetComponent } from '../om-prosjektet/ompro.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PubliserValgComponent } from '../publiser-valg/publiser-valg.component';
import { Reisetid } from './Reisetid';
import { KopierDagComponent } from '../kopier-dag/kopier-dag.component';
import { Avgang } from './dag-plan.component';

@Component({
  selector: 'app-master-plan',
  templateUrl: './master-plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class MasterPlanComponent implements OnInit {

  showSpinner = true;
  datoDag1: string = "Dag 1";
  datoDag2: string = "Dag 2";
  datoDag3: string = "Dag 3";
  reisetider: Reisetid[] = [];

  //Avgnager som sendes til dag_plan komponentene
  avgang_D1: Avgang[];
  avgang_D2: Avgang[];
  avgang_D3: Avgang[];
  LokaleIngenTransportId: number = 0;

  avreiseEn = ""
  avreiseTo = ""

  constructor(private service: PlanService, private dialog: MatDialog) { }

  AktivitetMTidsData: any[] = [];
  filtersLoaded: Promise<boolean>;
  visAvreisetider : boolean = false;
  leir: Leir;
  ingenTpmLagtTil = false;
  tabIndex = 0;



  hentAllData(){

    this.service.hentAllPlanData().subscribe((data : any) => {

        this.reisetider = data.reisetider;
        this.AktivitetMTidsData = data.aktivitetMTid;
        this.leir = data.leir;

        var date = new Date();
        var date2 = new Date(data.leir.datoFra);
        this.leir.datoFra = new Date(date2.getTime() - (date.getTimezoneOffset() * 60000));


        //Setter datoer
        //this.leir.datoFra = new Date(data.leir.datoFra);
        this.leir.datoTil = new Date(data.leir.datoTil);


        this.avgang_D1 = data.avgang_D1;
        this.avgang_D2 = data.avgang_D2;
        this.avgang_D3 = data.avgang_D3;

        this.LokaleIngenTransportId = data.lokaleIngenTransportId;

        this.showSpinner = false;
        this.setDatoerOgTabIndex();
        this.filtersLoaded = Promise.resolve(false);


    })

  }




  ngOnInit() {
    this.hentAllData();
    /*
    this.getAktivLeir();
    this.hentReisetid()
    this.service.getPlan().subscribe(res => {

        this.showSpinner = false;

        this.AktivitetMTidsData = (res as []);

        this.filtersLoaded = Promise.resolve(false);




    })*/
  }

  opneAvreistider(){
    this.visAvreisetider = !this.visAvreisetider

  }

  hentReisetid(){
    this.service.getReisetid().subscribe(res => {
      this.reisetider = (res as []);
    })
  }




  setDatoerOgTabIndex() {


      const dato_fra: Date = new Date(this.leir.datoFra/*.replace(/-/g, '\/').replace(/T.+/, '')*/);

      var day = (60 * 60 * 24 * 1000)

      let datoDag1: Date = new Date(dato_fra.getTime() + day);
      let datoDag2: Date = new Date(dato_fra.getTime() + (day * 2));
      let datoDag3: Date = new Date(dato_fra.getTime() + (day * 3));

      this.datoDag1 = datoDag1.toJSON().slice(8, 10) + "." + datoDag1.toJSON().slice(5, 7);
      this.datoDag2 = datoDag2.toJSON().slice(8, 10) + "." + datoDag2.toJSON().slice(5, 7);
      this.datoDag3 = datoDag3.toJSON().slice(8, 10) + "." + datoDag3.toJSON().slice(5, 7);

      const toToday = new Date();

      if(datoDag2.toDateString() === toToday.toDateString()){
        this.tabIndex = 1;
      }

      if(datoDag3.toDateString() === toToday.toDateString()){
        this.tabIndex = 2;
      }





      //this.avreiseEn = leir.klAvreiseR1;
      //this.avreiseTo = leir.klAvreiseR2;

      //this.avreiseEn = leir.klAvreiseR1;
      //this.avreiseTo = leir.klAvreiseR2;


  }



  kopierDag() {
    this.dialog.open(KopierDagComponent, {
       disableClose: true,
       autoFocus: false,
       maxWidth : "90vw"
     });
   }



  /*publiseringsVersjon() {
   this.dialog.open(PubliserValgComponent, {
      disableClose: false,
      autoFocus: false,
    });
  }*/

  lastInnPaaNytt() {
    window.location.reload()
    /*console.log("tst");
    this.service.getPlan().subscribe(res => {
      this.AktivitetMTidsData = (res as []);
      this.filtersLoaded = Promise.resolve(true);

    })*/
  }

  changeTabIndex(event : any){
    //this.onChangeTabIndex(event);
    this.tabIndex = event.index

    //turned off 25.11.22 as i do not understand why it is necessary. 19.12.22: value changes never got stored in states - therfore
    //this.hentAllData();
    this.filtersLoaded = (undefined);
    this.showSpinner = true;


    this.service.hentAllPlanData().subscribe((data : any) => {

        this.AktivitetMTidsData = [...data.aktivitetMTid];

        this.avgang_D1 = [...data.avgang_D1];
        this.avgang_D2 = [...data.avgang_D2];
        this.avgang_D3 = [...data.avgang_D3];



        this.filtersLoaded = Promise.resolve(false);
        this.showSpinner = false;


    })
  }
}




export interface Leir {
  leirnavn: string,
  antallDeltakere: number,
  datoFra: Date,
  datoTil: Date,
  klAvreiseR1: string,
  klAvreiseR2 : string,
  erTransportplanAktiv : string,
  profundoProsjektnummer : string,
  id : number
}
