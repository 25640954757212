import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { BrukerService } from '../../environments/bruker.service';

import { Router } from '@angular/router';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { AuthGuard } from '../auth/auth.guard';
import { OmProsjektetComponent } from '../om-prosjektet/ompro.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-logg-inn',
  templateUrl: './logg-inn.component.html',
  styleUrls: ['./logg-inn.component.css']
})
export class LoggInnComponent implements OnInit, AfterViewInit  {

  public bekreftelsesmld: any;
  public tekstfarge: string = "black";
  showSpinner = false;

  constructor(private fb: FormBuilder, private dialog: MatDialog, private http: BrukerService, private router: Router, private nav: NavMenuComponent, private auth: AuthGuard, private elementRef: ElementRef) { }

  formModel = this.fb.group({
    epost: ['', Validators.email],
    passord: ['', Validators.required],
  })

  ngAfterViewInit() {
    //this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'black';
  }

  ngOnInit() {
    if (localStorage.getItem('token') != null) {
      window.location.href = '/plan';
      return true;
    }
    else {
      //this.router.navigate(['/home']);
      return false;
    }
  }

  dialogRef: MatDialogRef<OmProsjektetComponent>;

  omProsjektet() {
    this.dialogRef = this.dialog.open(OmProsjektetComponent, {
      disableClose: false,
      autoFocus: false,
      panelClass: 'small-width-dialog'
    });


  }

  sendForm() {
    this.showSpinner = true;
    this.http.loggInn(this.formModel.value).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token);
        this.auth.login();
        //window.location.pathname = "/plan";
        window.location.href = '/plan';

      },
      err => {
        if (err.status == 400) {
          this.bekreftelsesmld = "Feil brukernavn eller passord"
          this.showSpinner = false;
        }
        else {
          this.bekreftelsesmld = "Ukjent feil";
          this.showSpinner = false;
          console.log(err);
        }

      }
    )
  }



  FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),

  ]);
}
