import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PlanService } from 'src/environments/plan.service';

@Component({
  selector: 'app-kopier-dag',
  templateUrl: './kopier-dag.component.html',
  styleUrls: ['./kopier-dag.component.css']
})
export class KopierDagComponent implements OnInit {

  laster: boolean = false;
  info: string = ""

  fraDag: string = "0";
  tilDag: string = "0";

  trengerUpdate = false


  constructor(public dialogRef: MatDialogRef<KopierDagComponent>, private http: PlanService) { }


  ngOnInit(): void {
  }

  onChangeDagFra(e : any){
    this.fraDag = e.target.value
  }

  onChangeDagTil(e : any){
    this.fraDag = e.target.value
  }

  lukk(){
    if(!this.trengerUpdate)
      this.dialogRef.close()
    else
      window.location.reload();
  }

  onSend(){
    this.laster = true
    this.trengerUpdate = true;
    this.info = "Kopierer dag "+this.fraDag+" til dag "+this.tilDag
    this.http.kopierDag(this.fraDag, this.tilDag)
      .subscribe((res : any) => {
        console.log(res);
        this.laster = false;
        this.info = "Fullført!"
      })
  }

}
