import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  getAvgang(dag: number) {
    return this.http.get(environment.apiBaseURI + '/Avgangs/' + dag);
  }

  constructor(private http: HttpClient) { }

  postPlan(formData) {
    return this.http.post(environment.apiBaseURI + '/Avgangs', formData);
  }

  putPlan(formData) {
    return this.http.put(environment.apiBaseURI + '/Avgangs/' + formData.id, formData);
  }

  slettPlan(id) {
    return this.http.delete(environment.apiBaseURI + '/Avgangs/' + id);
  }

  getPlan() {
    return this.http.get(environment.apiBaseURI + '/AktivitetMTids');
  }

  getTpm() {
    return this.http.get(environment.apiBaseURI + '/tpm');
  }

  putAktivitMTid(formData) {
    return this.http.put(environment.apiBaseURI + '/AktivitetMTids/' + formData.AktivitetMTid.id, formData, { observe: 'response' });
  }

  putAvreisetid(formData) {
    return this.http.post(environment.apiBaseURI + '/AktivitetMTids', formData, { observe: 'response' });
  }

  getAktivitetMTidsMLeirId(leirid : string, dag : number) {
    return this.http.get(environment.apiBaseURI + '/Sjoforplan/AktivitetMTidMLeirId' + "?leirid="+leirid+"&dag="+dag);
  }

  getAvgangMLeirId(dag: number, leirid : number) {
    return this.http.get(environment.apiBaseURI + '/Sjoforplan/AvgangMLeirId' +"?dag=" + dag + "&leirid=" + leirid);
  }

  getAktivLeir() {
    return this.http.get(environment.apiBaseURI + '/Leir/GetBrukerSinLeir');
  }

  getReisetid() {
    return this.http.get(environment.apiBaseURI + '/Lokales/Reisetid');
  }

  hentAllPlanData() {
    return this.http.get(environment.apiBaseURI + '/Plan/plan');
  }

  hentAvreisetid() {
    return this.http.get(environment.apiBaseURI + '/Avgangs/getAvreisetid');
  }

  endreAvreisetidForAlle(KlAvreiseR1 : string, KlAvreiseR2 : string) {
    return this.http.put(environment.apiBaseURI + '/Avgangs/putAvreisetid', {}, { params : {KlAvreiseR1 : KlAvreiseR1, KlAvreiseR2 : KlAvreiseR2}});
  }

  kopierDag(fraDag : any, tilDag : any) {
    return this.http.put(environment.apiBaseURI + '/Avgangs/kopierDag', {}, { params : { fraDag, tilDag}});
  }


  

}
