import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.css']
})
export class ConfirmdialogComponent{
  constructor(public dialogRef: MatDialogRef<ConfirmdialogComponent>) {
  }

  public confirmMessage: string;
  public rodTekst: string;
  public hovedtekst: string;

  ngOnInit() {
  }
}
