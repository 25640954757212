import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { LokaleService } from 'src/environments/envirlokale/lokale.service';
import { AktivitetService } from 'src/environments/enviraktivitet/aktivitet.service';

import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Lokale } from '../plan/Reisetid';


@Component({
  selector: 'app-akt-data',
  templateUrl: './nyAktivitet.component.html',
  styleUrls: ['./aktivitet.component.css']
})
export class NyAktivitetComponent implements OnInit {

  constructor(private bankService: LokaleService, private fb: FormBuilder, private service: AktivitetService, private router: Router, private snackBar: MatSnackBar) {
    this.nyAktivitet = this.fb.group({
      published: true,
      credentials: this.fb.array([]),
    });
  }


  senderSkjema: boolean = false;



  lokaler = [];
  notification = null;

  nyAktivitet: FormGroup;

  id: FormControl;
  navn: FormControl;
  sammeLokaleHverDag : FormControl;

  lokaleD1: FormControl;
  lokaleD2: FormControl;
  lokaleD3: FormControl;

  antallDeltakere: FormControl;
  antallLedere: FormControl;
  kapasitet: FormControl;
  kommentar: FormControl;
  //Hi
  fornavn: FormControl;
  etternavn: FormControl;
  epost: FormControl;
  telefonnr: FormControl;




  valgtLokale = 0;

  openSnackBar(message: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-red'];
    config.duration = 6000;
    this.snackBar.open(message, undefined, config);
  }




  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.bankService.getLokale()
      .subscribe(res => {
          (res as []).forEach((x : Lokale) => {
            if(x.lokaleTilIngenTransport == true){
              x.navn = "Trenger ikke transport";
            }
            this.lokaler.push(x);
          })

          this.nyAktivitet.patchValue(
            { 
              lokaleD1: { id: this.lokaler[0].id },
              lokaleD2: { id: this.lokaler[0].id },
              lokaleD3: { id: this.lokaler[0].id } 
            }
          )
        
        })

       
  }



  createFormControls() {
    this.id = new FormControl(0);
    this.navn = new FormControl('', Validators.required);

    this.lokaleD1 = new FormControl("", Validators.required);
    this.lokaleD2 = new FormControl("", Validators.required);
    this.lokaleD3 = new FormControl("", Validators.required);

    this.sammeLokaleHverDag = new FormControl(true, Validators.required);

    this.antallDeltakere = new FormControl('', Validators.required);
    this.antallLedere = new FormControl('', Validators.required);
    this.kapasitet = new FormControl('', Validators.required);
    this.kommentar = new FormControl('');

    //Hi
    this.fornavn = new FormControl('');
    this.etternavn = new FormControl('');
    this.epost = new FormControl('');
    this.telefonnr = new FormControl('');
  }

  createForm() {
    this.nyAktivitet = new FormGroup({
      id: this.id,
      navn: this.navn,
      sammeLokaleHverDag : this.sammeLokaleHverDag,
      lokaleD1: this.fb.group({
          id: [""]
      }),
      lokaleD2: this.fb.group({
        id: [""]
      }),
      lokaleD3: this.fb.group({
          id: [""]
      }),
      kommentar: this.kommentar,
      antallDeltakere: this.antallDeltakere,
      antallLedere: this.antallLedere,
      kapasitet: this.kapasitet,

      //Hi
      fornavn: this.fornavn,
      etternavn: this.etternavn,
      epost: this.epost,
      telefonnr: this.telefonnr,
    });
  }

  avbryt() {
    if (!this.nyAktivitet.valid) {
      this.router.navigate(['/aktivitet']);
    }
    
  }

  sendForm() {
      //checking if lokale should be same every day
      this.senderSkjema = true;
      if(this.nyAktivitet.value.sammeLokaleHverDag === true){
        this.nyAktivitet.patchValue({
          lokaleD2 : { id : this.nyAktivitet.controls.lokaleD1.value.id },
          lokaleD3 : { id : this.nyAktivitet.controls.lokaleD1.value.id } 
        });   
      }
      this.service.postAktivitet(this.nyAktivitet.value).subscribe(

        response => {
          if (response.ok) {
            this.router.navigate(['/aktivitet']);
          }
        },       
        error => {
          console.log(error);
          this.notification = { class: 'text-success', message: this.openSnackBar(error.error.message) };
        }
        
      );
      /*timer(4000).subscribe(x => {*/ //})
    
   
  }


  FormControl = new FormControl('', [
    Validators.required,
    Validators.email,

  ]);

}


